<template>
  <div class="pay-invoice-container container">
    <template v-if="qrPay">
      <h1>{{ $t("messages.input.head") }}</h1>
      <BillInfo
        :company="billInfo.company"
        :type="billInfo.type"
        :billDate="billInfo.billDate"
      ></BillInfo>
      <p class="input-info" v-html="$t('messages.input.text')"></p>
      <InvoiceInstruction></InvoiceInstruction>
    </template>
    <template v-else>
      <h1>{{ $t("messages.entry.head") }}</h1>
      <p v-html="$t('messages.entry.text')"></p>
      <InvoiceInstruction></InvoiceInstruction>
      <p class="company-info">
        {{ $t("messages.bill.to") }}
        <span style="color: red">*</span>:
      </p>
      <p>
        <input
          v-bind:class="{ error_border: !validation.errCompany }"
          v-model="billInfo.company"
          class="input_company"
          minlength="3"
          maxlength="100"
          @input="validation.errCompany = true"
        />
      </p>
      <p id="required" v-bind:class="{ error_msg: !validation.errCompany }">
        <span v-html="$t('messages.entry.required')"></span>
      </p>
    </template>
    <div
      class="table-responsive table_window"
      data-emptytext="FedEx - Table Component (Advanced)"
    >
      <table class="table invoice_table short_table" id="inv_table">
        <thead>
          <tr>
            <th data-parsys-path="parsys_th_0_1">
              {{ $t("messages.invoice.number") }}
            </th>
            <th style="padding-right: 0%" data-parsys-path="parsys_th_0_4">
              {{ $t("messages.invoice.topay") }} ({{
                $t(`messages['${$filter.getCurrency($countryCode)}']`)
              }})
            </th>
            <!-- <th><div id="add_btn2" @click="addItem"><b-icon-plus-circle /></div></th> -->
            <th>
              <div id="add_btn2" class="plus_icon header_icon" @click="addItem">
                +
              </div>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(inv, index) in checkPayFlag" :key="inv.id">
            <td
              v-if="inv.dueAmount < 1 && !inv.payFlag"
              data-parsys-path="parsys_td_1_1"
            >
              <input
                type="text"
                size="10"
                v-bind:class="{ error_border: inv.isErrorBorder }"
                maxlength="9"
                v-model="inv.invno"
                v-on:keypress="isNumber($event)"
                @change="checkInvoice(inv, index)"
                class="invoice_id only_allow_numbers invoice_add_input"
              />
              <p
                v-show="inv.errInvoice"
                class="error_msg"
                v-html="inv.errInvoice"
              ></p>
            </td>
            <td
              v-if="inv.invno !== null && inv.invno !== '' && inv.payFlag"
              style="vertical-align: middle"
            >
              {{ inv.invno }}
            </td>
            <td
              v-if="
                (inv.dueAmount < 1 && !inv.payFlag) ||
                (inv.invno !== null && inv.invno !== '' && inv.payFlag)
              "
              data-parsys-path="parsys_td_1_4"
            >
              <input
                type="text"
                v-model="inv.payAmount"
                maxlength="15"
                v-bind:class="{ error_border: inv.isAmtErrorBorder }"
                v-on:keypress="isAmount($event)"
                @change="
                  checkInvoice(inv, index);
                  computeTotalAmount(inv.id, inv);
                "
                class="billPayAmount invoice_add_input short"
              />
              <p
                v-show="inv.errAmount"
                class="error_msg"
                v-html="inv.errAmount"
              ></p>
            </td>
            <td>
              <span v-if="inv.dueAmount < 1 && !inv.payFlag">
                <!-- <div id="icon_one" @click="removeItem(inv.id, inv)" class="remove_btn2 plus_icon"><b-icon-dash-circle /></div> -->
                <div
                  id="icon_one"
                  @click="removeItem(inv.id, inv)"
                  class="remove_btn2 plus_icon"
                >
                  &mdash;
                </div>
              </span>
              <span v-else></span>
            </td>
          </tr>
        </tbody>
        <tfoot>
          <tr class="total">
            <td class="total_text">{{ $t("messages.invoice.total") }}</td>
            <td id="total" class="total_amount">
              {{ $filter.changeAmountFormate(totalAmountToPay, $countryCode) }}
            </td>
          </tr>
        </tfoot>
      </table>
    </div>
    <hr />
    <div class="invoice_table_buttons">
      <button
        id="bill_review"
        type="button"
        class="button"
        v-if="showBackButton"
        name="back"
        @click="back"
        style="margin-right: 5px"
      >
        {{ $t("messages.button.back") }}
      </button>
      <button
        id="bill_review"
        type="button"
        class="button"
        name="next"
        @click="next"
      >
        {{ $t("messages.button.next") }}
      </button>
    </div>
  </div>
</template>

<script>
import BillInfo from "../shared/BillInfo.vue";
import InvoiceInstruction from "../shared/InvoiceInstruction.vue";
import { useRoute } from "vue-router";
import { validateInvoices } from "../../services/service.js";
export default {
  name: "PayInvoice",
  components: {
    BillInfo,
    InvoiceInstruction,
  },
  data() {
    return {
      qrPay: Boolean,
      companyName: "",
      billPay: {
        items: [],
      },
      validation: {
        errCompany: true,
        itemsError: false,
      },
      totalAmountToPayOnSel: 0,
      totalAmountToPay: 0,
      totalAmountPaidUnpaid: 0,
      colAmountHead: "",
      billList: [],
      checkedAll: true,
      page: 0,
      prevRoute: null,
      showBackButton: true,
      billInfo: {
        company: "",
        type: "",
        billDate: "",
      },
    };
  },
  methods: {
    /**
     * confirm form validation and move to confirm payment page
     * @returns {void}
     */
    next: function () {
      if (this.$countryCode !== "id") {
        if (!this.checkForm() || this.totalAmountToPay === 0) {
          return;
        }
        this.navigateToConfirmPayment();
      } else {
        this.nextId();
      }
    },
    /**
     * navigate to confirm payment page for Indonatia
     * @returns {void}
     */
    nextId: function () {
      if (!this.checkForm() || this.totalAmountToPay === 0) {
        return;
      }
      const reply = this.validateInvoice();
      reply
        .then(() => {
          this.navigateToConfirmPayment();
        })
        .catch((err) => {
          const errors = err.response?.data;
          for (var i = 0; i < this.billList.length; i++) {
            if (
              errors.errors[i].msgCode == "INVALID_LENGTH" ||
              errors.errors[i].msgCode == "INVALID_NUMBER"
            ) {
              this.setError(
                this.billList[i],
                i,
                "isErrorBorder",
                true,
                "errInvoice",
                this.$t("messages.field.invnum")
              );
            }
            if (errors.errors[i].msgCode == "INVALID_AMOUNT") {
              this.setError(
                this.billList[i],
                i,
                "isAmtErrorBorder",
                true,
                "errAmount",
                this.$t("messages.field.required")
              );
            }
          }
        });
    },

    /**
     * navigate to confirm payment page
     * @returns {void}
     */
    navigateToConfirmPayment: function () {
      sessionStorage.removeItem("billPay");
      sessionStorage.removeItem("totalAmountToPay");
      this.billList = this.billList.map((bill) => {
        bill.payAmount = bill.payAmount.replaceAll(",", "");
        return bill;
      });
      sessionStorage.setItem("billPay", JSON.stringify(this.billList));
      sessionStorage.setItem("billInfo", JSON.stringify(this.billInfo));
      sessionStorage.setItem("totalAmountToPay", JSON.stringify(this.totalAmountToPay));
      this.$router.push("/ConfirmPayment");
    },
    back: function () {
      sessionStorage.removeItem("billPay");
      this.billList = this.billList.map((bill) => {
        bill.payAmount = bill.payAmount.replaceAll(",", "");
        return bill;
      });
      sessionStorage.setItem("billPay", JSON.stringify(this.billList));

      this.$router.back();
    },
    /**
     * add Item check
     * @returns {void}
     */
    addItem: function () {
      if (!this.checkForm()) return;
      this.addNewItem();
    },
    /**
     * add Item
     * @returns {void}
     */
    addNewItem: function () {
      var newItem = {
        invno: null,
        type: null,
        date: null,
        dueAmount: null,
        payAmount: "0",
        error: true,
        payFlag: false,
      };
      this.runNumber += 1;
      this.billList.push(newItem);
    },
    /**
     * remove Item
     * @returns {void}
     */
    removeItem: function (index, item) {
      if (this.billList.length >= 1) {
        var idx = this.billList.indexOf(item);
        this.billList.splice(idx, 1);
      }
      this.computeTotalAmount(null, null);
    },

    /**
     * check form validation
     * @returns {boolean}
     */
    checkForm: function () {
      var result = true;
      if (this.billInfo.company == null || this.billInfo.company == "") {
        this.validation.errCompany = false;
        result = false;
      } else {
        var len = this.billInfo.company.length;
        if (len < 3 || len > 100) {
          this.validation.errCompany = false;
          result = false;
        } else this.validation.errCompany = true;
      }
      // var invno = null;
      for (var i = 0; i < this.checkPayFlag.length; i++) {
        if (!this.checkInvoice(this.checkPayFlag[i], i)) {
          result = false;
        }
      }
      return result;
    },

    /**
     * check invoice validation
     * @returns {boolean}
     */
    checkInvoice: function (inv, index) {
      var invno = inv.invno;
      var invamt = inv.payAmount;
      var pattern = /[^0-9]+/g;
      if (inv.errInvoice) {
        inv.errInvoice = null;
      }
      if (inv.errAmount) {
        inv.errAmount = null;
      }
      var strInvAmt = invamt;
      if (strInvAmt && strInvAmt.includes(",")) {
        strInvAmt = strInvAmt.replace(",", "");
      }
      if (invno == null || invno == "") {
        this.setError(
          inv,
          index,
          "isErrorBorder",
          true,
          "errInvoice",
          this.$t("messages.field.required")
        );
        return false;
      } else if (invno.length != 9 || pattern.test(invno)) {
        this.setError(
          inv,
          index,
          "isErrorBorder",
          true,
          "errInvoice",
          this.$t("messages.field.invnum")
        );
        return false;
      } else if (invamt == null || invamt == "") {
        this.setError(
          inv,
          index,
          "isAmtErrorBorder",
          true,
          "errAmount",
          this.$t("messages.field.required")
        );
        return false;
      } else if (invamt < 1) {
        this.setError(
          inv,
          index,
          "isAmtErrorBorder",
          true,
          "errAmount",
          this.$t("messages.field.required")
        );
        return false;
      } else {
        this.setError(inv, index, "isErrorBorder", false);
        this.setError(inv, index, "isAmtErrorBorder", false);
        return true;
      }
    },

    /**
     * set error on filed
     * @returns {void}
     */
    setError(
      inv,
      invIndex,
      errorType = undefined,
      hasError = undefined,
      errorTitle = undefined,
      errorMsg = undefined
    ) {
      this.checkPayFlag.forEach((bill, index) => {
        if (invIndex === index) {
          if (errorType !== undefined) {
            bill[errorType] = hasError;
            bill[errorTitle] = errorMsg;
            bill.error = hasError;
          }
        }
      });
    },

    /**
     * compute total Amount
     * @returns {void}
     */
    computeTotalAmount: function (index, item) {
      var total = 0;
      total = this.billList.reduce(function (acc, inv) {
        var amtToPayTotal;
        if (
          !(
            inv.dueAmount !== "0.00" &&
            inv.dueAmount !== "0" &&
            inv.dueAmount != null &&
            !inv.payFlag
          )
        ) {
          if (inv.payAmount == "") {
            amtToPayTotal = 0;
          } else {
            amtToPayTotal = parseFloat(inv.payAmount.split(",").join(""));
          }
        } else if (status === "success") {
          amtToPayTotal = parseFloat(inv.payAmount.split(",").join(""));
        } else {
          amtToPayTotal = 0;
        }
        if (!isNaN(amtToPayTotal)) {
          return acc + amtToPayTotal;
        }
      }, 0);
      this.totalAmountToPay = total;
      if (index !== null && item !== null) {
        this.formatInvAmt(index, item);
      }
    },

    /**
     * format Intiger amount
     * @returns {void}
     */
    formatInvAmt: function (index, item) {
      var idx = this.billList.indexOf(item);
      var amt = this.billList[idx].payAmount;
      this.billList[idx].payAmount = this.$filter.changeAmountFormate(
        amt,
        this.$countryCode
      );
    },
    isNumber: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (charCode > 31 && (charCode < 48 || charCode > 57)) {
        evt.preventDefault();
      } else {
        return true;
      }
    },
    isAmount: function (evt) {
      evt = evt ? evt : window.event;
      var charCode = evt.which ? evt.which : evt.keyCode;
      if (
        charCode > 31 &&
        (charCode < 48 || charCode > 57) &&
        charCode !== 46
      ) {
        evt.preventDefault();
      } else {
        return true;
      }
    },

    /**
     * valiadte Invoice Async
     * @returns {promise}
     */
    validateInvoice: function () {
      var outBillPay = JSON.stringify(this.checkPayFlag);
      var stripped = outBillPay.replace(/,(?!["{}[\]])/g, "");
      const billList = JSON.parse(stripped);
      const invoiceItems = billList.map((inv) => {
        return {
          invno: inv.invno,
          dueAmount: inv.dueAmount,
          payAmount: inv.payAmount,
          payFlag: inv.payFlag,
        };
      });
      let routerQueryParams = sessionStorage.getItem("routerQueryParams");
      if (routerQueryParams) {
        routerQueryParams = JSON.parse(routerQueryParams);
      }
      const payload = {
        successUrl: null,
        failureUrl: null,
        billPay: {
          country: routerQueryParams.countryCode,
          language: routerQueryParams.lang,
          currency: routerQueryParams.currency,
          emailAddress: null,
          company: this.billInfo.company,
          invoiceItems,
        },
      };
      return validateInvoices(
        this.$countryCode,
        payload,
        routerQueryParams.lang
      );
    },
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.prevRoute = from.fullPath;
    });
  },
  computed: {
    checkPayFlag: function () {
      return this.billList.filter(function (item) {
        if (item.dueAmount < 1 && !item.payFlag) {
          return item;
        }
        if (item.invno != null && item.invno !== "" && item.payFlag) {
          return item;
        }
      });
    },
  },
  beforeMount() {
    this.qrPay = sessionStorage.getItem("qrPay") ? true : false;
    if (this.qrPay) {
      const billInfoFromStorage = sessionStorage.getItem("billInfo");
      const { company, type, billDate } = JSON.parse(billInfoFromStorage);
      this.billInfo.company = company;
      this.billInfo.type = type;
      this.billInfo.billDate = billDate;
      this.billList = [...JSON.parse(sessionStorage.getItem("billPay"))];
      this.billList.forEach((bill) => {
        const payAmount = bill.payAmount || bill.dueAmount;
        bill.payAmount = this.$filter.changeAmountFormate(
          payAmount,
          this.$countryCode
        );
        bill.error = false;
      });
      this.computeTotalAmount(null, null);
      // const isNotEmpty = this.billList.some((inv) => {
      //   return inv.payFlag === true || ( inv.invno !== null && inv.dueAmount === null);
      // });
      const isNotEmpty = this.billList.some(function (item) {
        if (item.dueAmount < 1 && !item.payFlag) {
          return item;
        }
        if (item.invno != null && item.invno !== "" && item.payFlag) {
          return item;
        }
      });
      if (!isNotEmpty) {
        this.addNewItem();
      }
    } else {
      const manuallyEnteredBillList = sessionStorage.getItem("billPay");
      if (manuallyEnteredBillList) {
        const billInfoFromStorage = sessionStorage.getItem("billInfo");
        const { company } = JSON.parse(billInfoFromStorage);
        this.billInfo.company = company;
        this.billList = [...JSON.parse(manuallyEnteredBillList)];
        this.billList.forEach((bill) => {
          const payAmount = bill.payAmount || bill.dueAmount;
          bill.payAmount = this.$filter.changeAmountFormate(
            payAmount,
            this.$countryCode
          );
        });
        this.computeTotalAmount(null, null);
      } else {
        this.addNewItem();
      }
    }

    this.$countryCode =
      typeof this.$countryCode === "string"
        ? this.$countryCode
        : localStorage.getItem("countryCode");
  },
  mounted() {
    const route = useRoute();
    if (route.query?.entryFrom === "manualInvoice") {
      this.showBackButton = false;
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
table{
  margin-top : 10px;
}
.company-info {
  padding-top: 10px;
}
.input-info {
  padding-top: 10px;
}
p {
  margin: 0 !important;
}
.pay-invoice-container {
  text-align: left;
  margin-bottom: 20px;
  color: #000;
}
.pay-invoice-container h1 {
  font-weight: normal;
  font-size: 26px;
  color: #4d148c;
  padding: 0;
  margin: 20px 0 20px 0;
  line-height: 45px;
}
.pay-invoice-container .button {
  background-color: #4d148c;
  color: #fff;
}
.error_msg {
  color: red;
}
.error_border {
  border-color: red;
}
</style>
